import React from 'react';
import s from '../../locale/about/s.json';

import styles from './About.module.scss';
import aboutImage from '../../images/about.jpg';
import {Text, Page, Grid} from '../../components';

interface Props {
  data: any;
  t?: any;
}

type State = {};
type ComposedProps = Props;

export default class About extends React.PureComponent<ComposedProps, State> {
  render() {
    const t = this.props.t || (() => []);
    return (
      <Page title="About">
        <Page.Layout>
          <div className={styles.PageBanner}>
            <div className={styles.PageBannerImageContainer}>
              <img className={styles.PageBannerImage} src={aboutImage} />
            </div>
            <div className={styles.PageBannerContent}>
              <Grid>
                <Grid.ScreenWidth>
                  <Text
                    className={styles.PageBannerTitle}
                    tag="h1"
                    color="var(--color-heading-neg)"
                  >
                    {t(s.aboutTitle)}
                  </Text>
                </Grid.ScreenWidth>
              </Grid>
            </div>
          </div>
          <Page.Section>
            <Grid>
              <Text>
                {t(s.blurb1)} 
              </Text>
              <Text>
                {t(s.blurb2)}
              </Text>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7324322922896!2d103.88305351475402!3d1.336790799025024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1791f5271c5d%3A0xf4b5aa2e3980ec73!2s31%20Harrison%20Rd%2C%20Singapore%20369649!5e0!3m2!1sen!2sco!4v1572298795460!5m2!1sen!2sco"
                width="100%"
                height="320"
                frameBorder="0"
                style={{border: 0}}
                allowFullScreen={true}
              />
            </Grid>
          </Page.Section>
        </Page.Layout>
      </Page>
    );
  }
}
